.app {
  position: relative;
  height: 100vh; 
  overflow: hidden; 
  display: flex; 
  justify-content: center;
  align-items: center; 
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1; 
  transform: translate(-50%, -50%);
}

.card {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  width: 90%; 
  max-width: 600px; 
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.icons {
  margin-top: 30px;
}

.icons a {
  color: white; 
  transition: transform 0.3s, color 0.3s;
  margin: 10px;
}

.icons a:hover {
  transform: scale(1.2); 
  color: #0055c4; 
}

.profile-image {
  width: 180px; 
  height: 180px; 
  border-radius: 50%; 
}

p {
  color: white;
}

h2 {
  color: white;
}


@media (max-width: 768px) {
  .profile-image {
    width: 100px;
    height: 100px; 
  }
  
  .card {
    padding: 15px; 
  }
}

@media (max-width: 480px) {
  .card {
    padding: 10px; 
  }

  p {
    font-size: 14px; 
  }

  h2 {
    font-size: 18px; 
  }
}
